import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import cx from 'classnames'
import {Menu, MenuItem} from '@material-ui/core'
import {getMyAccount, logout} from 'redux/action/authAction'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import MapIcon from 'component/icon/MapIcon'
import MachineIcon from 'component/icon/MachineIcon'
import UserIcon from 'component/icon/UsersIcon'
import {ReactComponent as UserSetting} from 'style/asset/user-setting.svg'
import {setActivePage} from 'redux/action/activePageAction'
import {Trans} from '@lingui/macro'
import {USER_ROLES} from 'helper/constants'
import useWindowSize from 'helper/useWindowSize'
const logo = 'logo.png'

const LeftPanel = (props) => {
  const {
    logout,
    classes,
    setActivePage,
    activePage,
    getMyAccount,
    myAccount,
    handleOpenAccountSettings,
  } = props

  const handleIconClick = (path) => (e) => {
    setActivePage(path)
  }

  const [accountAnchor, setAccountAnchor] = React.useState(null)
  const {isMediaMobile, isMediaScreen} = useWindowSize()

  const accountMenuClick = (event) => {
    if (accountAnchor === null) {
      setAccountAnchor(event.currentTarget)
    } else {
      setAccountAnchor(null)
    }
  }

  const handleLogout = () => {
    logout()
  }

  const handleSettings = () => {
    accountMenuClick()
    handleOpenAccountSettings()
  }

  useEffect(() => {
    getMyAccount()
  }, [])

  const settings = (
    <>
      <BoxFullWidth py={4} px={2} className={classes.leftPanelUserSettingIcon}>
        <UserSetting onClick={accountMenuClick} />
        <BoxFullWidth pb={0.5}>{myAccount?.name}</BoxFullWidth>
        <BoxFullWidth pb={0.5} className={classes.myAccountRole}>
          {myAccount?.roles?.[0]?.name &&
            USER_ROLES.find((role) => role.value === myAccount.roles[0].name).label}
        </BoxFullWidth>
      </BoxFullWidth>
      <Menu
        anchorEl={accountAnchor}
        keepMounted
        open={accountAnchor !== null}
        onClose={accountMenuClick}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      >
        <MenuItem onClick={handleSettings}>
          <Trans>Settings</Trans>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Trans>Logout</Trans>
        </MenuItem>
      </Menu>
    </>
  )

  return (
    <GridContainer
      direction={isMediaScreen ? 'column' : 'row'}
      justify="space-between"
      className={cx(classes.leftPanel, classes.leftPanelTopIcons)}
    >
      <GridItem
        container
        direction={isMediaScreen ? 'column' : 'row'}
        align="center"
        justify={isMediaScreen ? 'center' : 'space-around'}
      >
        {isMediaScreen && (
          <GridItem className={classes.leftPanelLogo}>
            <img src={logo} className={classes.leftPanelLogoImage} alt={'logo'} />
          </GridItem>
        )}
        <GridItem
          className={cx(
            classes.leftPanelMenuIcon,
            activePage === 'map' ? classes.leftPanelMenuIconActive : ''
          )}
        >
          <span className={classes.iconName} onClick={handleIconClick('map')}>
            <MapIcon />
            <Trans>Map</Trans>
          </span>
        </GridItem>
        {myAccount.isManager && (
          <GridItem
            className={cx(
              classes.leftPanelMenuIcon,
              activePage === 'machines' ? classes.leftPanelMenuIconActive : ''
            )}
          >
            <span className={classes.iconName} onClick={handleIconClick('machines')}>
              <MachineIcon />
              <Trans>Machines</Trans>
            </span>
          </GridItem>
        )}
        {myAccount.isManager && (
          <GridItem
            className={cx(
              classes.leftPanelMenuIcon,
              activePage === 'users' ? classes.leftPanelMenuIconActive : ''
            )}
          >
            <span className={classes.iconName} onClick={handleIconClick('users')}>
              <UserIcon />
              <Trans>Users</Trans>
            </span>
          </GridItem>
        )}
        {isMediaMobile && (
          <GridItem
            className={cx(
              classes.leftPanelMenuIcon,
              activePage === 'users' ? classes.leftPanelMenuIconActive : ''
            )}
          >
            {settings}
          </GridItem>
        )}
      </GridItem>
      {isMediaScreen && (
        <GridItem container direction={isMediaScreen ? 'column' : 'row'} align="center">
          {settings}
        </GridItem>
      )}
    </GridContainer>
  )
}

LeftPanel.propTypes = {
  logout: PropTypes.func.isRequired,
  classes: PropTypes.object,
  setActivePage: PropTypes.func,
  activePage: PropTypes.string,
  myAccount: PropTypes.object,
  getMyAccount: PropTypes.func,
  handleOpenAccountSettings: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      setActivePage,
      getMyAccount,
    },
    dispatch
  )
}

export default withStyles(privateLayoutStyle)(
  connect((store) => {
    return {
      activePage: store.activePage.page,
      myAccount: store.user.myAccount,
    }
  }, mapDispatchToProps)(LeftPanel)
)
