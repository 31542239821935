import {MEDIA_MOBILE, MEDIA_SCREEN} from 'helper/constants'

// accessing theme which is active in ThemeProvider
const machinesPageStyle = (theme) => ({
  topBar: {
    background: theme.palette.secondary.light,
    borderBottom: '1px solid ' + theme.palette.secondary.main,
  },
  topBarIcon: {
    width: 44,
    height: 44,
    paddingRight: 8,
    fill: theme.palette.primary.main + ' !important',
  },

  // container for table which stretch full height
  tableContainer: {
    height: '100%',
    overflow: 'auto',
  },

  tableHead: {
    borderBottom: '1px solid ' + theme.palette.secondary.main,
    height: 60,
  },

  tableHeadFilter: {
    textTransform: 'uppercase',
    fontWeight: 700,
    textDecoration: 'underline',
  },

  tableRow: {
    height: 60,
    fontSize: 20,
    borderBottom: '2px dashed ' + theme.palette.secondary.main,
    boxSizing: 'border-box',
    [MEDIA_MOBILE]: {
      fontSize: 16,
    },
  },

  tableRowLoading: {
    padding: 0,
    width: 36,
    height: 36,
  },

  tableRowName: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    [MEDIA_MOBILE]: {
      fontWeight: 500,
    },
  },

  tableRowText: {
    color: theme.palette.secondary.dark,
    fontWeight: 400,
  },

  // in line text input in table
  tableInput: {
    width: 'calc(100% - 16px)',
    '& input': {
      fontSize: '18px !important',
      height: 35,
      boxSizing: 'border-box',
    },
    '& .MuiSelect-select': {
      padding: '6px 0 7px 0',
      fontSize: '18px !important',
      height: 35,
      boxSizing: 'border-box',
    },
  },

  tableRowActions: {
    '& > div:first-child': {
      marginRight: 16,
    },
  },

  tableNonActive: {
    color: theme.palette.error.dark,
    fontWeight: 600,
  },
  tableActive: {
    color: theme.palette.success.dark,
    fontWeight: 600,
  },

  sortIcon: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  sortIconBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
      fill: theme.palette.primary.light,
    },
  },
  sortIconAsc: {
    '& svg': {
      '& g:first-child': {
        fill: theme.palette.primary.main,
      },
    },
  },
  sortIconDesc: {
    '& svg': {
      '& g:nth-child(2)': {
        fill: theme.palette.primary.main,
      },
    },
  },

  smallGridPadding: {
    '&:first-child': {
      paddingRight: 10,
    },
    '&:nth-child(2)': {
      paddingLeft: 10,
    },
  },

  submitFormButton: {
    padding: '12px 14px',
    height: 42,
    fontSize: 16,
    boxSizing: 'border-box',
  },

  machineListIcon: {
    [MEDIA_MOBILE]: {
      width: 18,
      height: 18,
      padding: 4,
      margin: 0,
    },
  },

  userTable: {
    [MEDIA_MOBILE]: {
      minWidth: '700px',
    },
    [MEDIA_SCREEN]: {
      minWidth: '900px',
    },
  },

  newUser: {
    width: '100%',
    paddingRight: '20px',
    paddingLeft: '20px',
    boxSizing: 'border-box',
    [MEDIA_MOBILE]: {
      paddingBottom: '100px',
    },
  },
})

export default machinesPageStyle
