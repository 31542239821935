import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import {compose} from 'redux'
import {connect} from 'react-redux'

const AccountSettingsForm = (props) => {
  const {classes, handleSubmit, myAccount} = props

  return (
    <Box p={5}>
      <Form onSubmit={handleSubmit}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} className={classes.smallGridPadding}>
                <Field
                  name="name"
                  label={<Trans>Name and surname</Trans>}
                  component={TextInput}
                  validate={required}
                  defaultValue={myAccount.name}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Field
                  name="email"
                  label={<Trans>E-mail</Trans>}
                  component={TextInput}
                  validate={required}
                  defaultValue={myAccount.email}
                />
              </GridItem>

              <GridItem container justify={'flex-end'}>
                <PrimaryButton
                  formSubmit={true}
                  textPadding={1}
                  size={'large'}
                  type="submit"
                  fullWidth={false}
                  text={<Trans>Edit</Trans>}
                />
              </GridItem>
            </GridContainer>
          </form>
        )}
      </Form>
    </Box>
  )
}

AccountSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
}

export default compose(
  withStyles(fieldStyle),
  connect((store) => {
    return {
      myAccount: store.user.myAccount,
    }
  })
)(AccountSettingsForm)
