import SingleCheckboxFilter from 'component/MapLayoutPage/SingleCheckboxFilter'
import {Trans} from '@lingui/macro'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import GridContainer from 'component/material/GridContainer'
import Switch from '@material-ui/core/Switch'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'

const FilterCheckboxes = ({
  classes,
  archivedFilter,
  activeWorkFilter,
  filters,
  handleChangeActiveWork,
  handleChangeArchived,
  handleChangeFilters,
}) => {
  const getArchivedSwitchLabel = () => {
    if (archivedFilter) {
      return (
        <span className={classes.switchLabel}>
          <Trans>Active</Trans>
          {' / '}
          <b>
            <Trans>Archived</Trans>
          </b>
        </span>
      )
    } else {
      return (
        <span className={classes.switchLabel}>
          <b>
            <Trans>Active</Trans>
          </b>
          {' / '}
          <Trans>Archived</Trans>
        </span>
      )
    }
  }

  return (
    <>
      <SingleCheckboxFilter
        handleChange={handleChangeFilters}
        checked={filters.includes('WORK')}
        label={<Trans>Work station</Trans>}
        name="WORK"
      />
      {filters.includes('WORK') && (
        <FormControlLabel
          className={classes.workColor}
          control={
            <Checkbox
              classes={{root: classes.filterSubCheckbox}}
              checked={activeWorkFilter}
              onChange={handleChangeActiveWork}
            />
          }
          label={
            <GridContainer alignItems={'center'} className={classes.filterIconText}>
              <Trans>Only active</Trans>
            </GridContainer>
          }
        />
      )}
      <SingleCheckboxFilter
        handleChange={handleChangeFilters}
        checked={filters.includes('DESTINATION')}
        label={<Trans>Destination</Trans>}
        name="DESTINATION"
      />

      <SingleCheckboxFilter
        handleChange={handleChangeFilters}
        checked={filters.includes('GAS_STATION')}
        label={<Trans>Gas station</Trans>}
        name="GAS_STATION"
      />

      <SingleCheckboxFilter
        handleChange={handleChangeFilters}
        checked={filters.includes('SERVICE_STATION')}
        label={<Trans>Service</Trans>}
        name="SERVICE_STATION"
      />

      <FormControlLabel
        control={
          <Switch
            className={classes.switchClasses}
            checked={archivedFilter}
            onChange={handleChangeArchived}
            color="primary"
          />
        }
        label={getArchivedSwitchLabel()}
      />
    </>
  )
}

export default withStyles(mapLayoutPageStyle)(FilterCheckboxes)
