import React from 'react'
import GridItem from 'component/material/GridItem'
import GridContainer from 'component/material/GridContainer'
import moment from 'moment'
import {DATE_TIME_FORMAT} from 'helper/constants'
import {DateTimePicker} from '@material-ui/pickers'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import EditIconButton from 'component/editField/EditIconButton'
import EditFieldDefaultValue from 'component/editField/EditFieldDefaultValue'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import CheckIconButton from 'component/editField/CheckIconButton'
import CloseIconButton from 'component/editField/CloseIconButton'
import cx from 'classnames'

/**
 * Component able of toggling edit/display single controlled fields
 *
 * @param defaultValue = initial value of field, displayed when not editing
 * @param value = actual value which change during onChange event
 * @param editing = true/false
 * @param name = name of the field on BE
 * @param onChange = function controlling field change
 * @param onSave = submit put request with new value
 * @param onEdit = setting field which is edited or cancelling edit with null
 * @returns {JSX.Element}
 */
const EditDateTimeField = (props) => {
  const {
    defaultValue,
    value,
    label,
    editing,
    onChange,
    onSave,
    onEdit,
    responsiveness = {xs: 12, sm: 6},
    classes,
    variant = 'inline',
  } = props

  return (
    <GridItem
      container
      {...responsiveness}
      className={cx(classes.borderBottom, classes.borderLeft)}
      alignItems={'center'}
    >
      <GridItem xs={true}>
        {editing ? (
          <DateTimePicker
            variant={variant}
            format={DATE_TIME_FORMAT}
            value={value}
            onChange={onChange}
            autoOk={true}
          />
        ) : (
          <EditFieldDefaultValue value={moment(defaultValue).format(DATE_TIME_FORMAT)} />
        )}
        <EditFieldLabel value={label} />
      </GridItem>
      <GridItem>
        {editing ? (
          <GridContainer justify={'flex-end'} alignItems={'center'} direction={'column'}>
            <CheckIconButton onSave={onSave} />
            <CloseIconButton onClose={onEdit(null)} />
          </GridContainer>
        ) : (
          <EditIconButton onEdit={onEdit(name)} />
        )}
      </GridItem>
    </GridItem>
  )
}

export default withStyles(locationDetailStyle)(EditDateTimeField)
