import React, {useEffect, useState} from 'react'
import GridItem from 'component/material/GridItem'
import Carousel from 'react-material-ui-carousel'
import Paper from '@material-ui/core/Paper'
import {Trans} from '@lingui/macro'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import DialogWindow from 'component/material/DialogWindow'
import {updateLocation, getLocation, getLocations} from 'redux/action/locationAction'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import EditCoordinateField from 'component/editField/EditCoordinateField'
import EditTextAreaField from 'component/editField/EditTextAreaField'
import EditTextField from 'component/editField/EditTextField'
import {getContract, updateContract} from 'redux/action/contractAction'
import {getShifts} from 'redux/action/shiftAction'
import EditContractDateField from 'component/editField/EditContractDateField'
import ImageCarousel from 'component/MapLayoutPage/locationDetail/ImageCarousel'
import LocationDetailTitleRow from 'component/MapLayoutPage/locationDetail/LocationDetailTitleRow'
import EditFieldRow from 'component/editField/EditFieldRow'
import cx from 'classnames'
import {getMachines} from 'redux/action/machineAction'
import EditSelectField from 'component/editField/EditSelectField'
import {
  CONTRACT_STATUS_LIST,
  CONTRACT_TYPE_LIST,
  CUBIC_METERS_TO_PRM,
  MATERIAL_TYPE_LIST,
} from 'helper/constants'
import EditCoordsForm from 'component/MapLayoutPage/locationDetail/EditCoordsForm'

const LocationDetail = (props) => {
  const {
    locationDetail,
    contractDetail,
    classes,
    updateLocation,
    updateContract,
    getContract,
    getLocation,
    getLocations,
    hasContract,
    onClose,
    getMachines,
    machineList,
    machineLoading,
    locationFromMapCoords,
    handleLocationFromMap,
    gettingLocationFromMap,
    pastShifts,
    getShifts,
    isManager,
  } = props

  const [openBigCarousel, setOpenBigCarousel] = useState(null)
  const [editingField, setEditingField] = useState(null)
  const [openEditCoords, setOpenEditCoords] = useState(false)

  const prepareLocationData = (field) => {
    let fieldName = Object.keys(field)[0]
    let submitData = {...locationDetail}

    delete submitData.contracts

    if (fieldName.includes('Description')) {
      return {...submitData, description: field[fieldName]}
    }
    return {...submitData, ...field}
  }

  const prepareContractData = (field) => {
    let fieldName = Object.keys(field)[0]
    let submitData = {...contractDetail}

    if (submitData.machine?.id) {
      submitData.machine_id = submitData.machine?.id
      delete submitData.machine
    }
    if (fieldName.includes('Description')) {
      return {...submitData, description: field[fieldName]}
    }
    if (fieldName === 'type' || fieldName === 'material_type') {
      return {...submitData, [fieldName]: field[fieldName].value}
    }
    if (fieldName === 'status') {
      return {...submitData, status: field.status.value}
    }
    if (fieldName === 'netto') {
      return {
        ...submitData,
        netto: field.netto / (contractDetail?.material_type === 'WOOD' ? 1 : CUBIC_METERS_TO_PRM),
      }
    }
    return {...submitData, ...field}
  }

  const handleEditingField = (fieldName) => (e) => {
    if (fieldName === 'locationDescription') {
      setEditingField({[fieldName]: locationDetail['description'] || ''})
    } else if (fieldName === 'contractDescription') {
      setEditingField({[fieldName]: contractDetail['description'] || ''})
    } else {
      setEditingField({[fieldName]: locationDetail[fieldName] || ''})
    }
  }

  const handleEditingContractType = (fieldName) => (e) => {
    setEditingField({
      [fieldName]: CONTRACT_TYPE_LIST.find((type) => type.value === contractDetail?.type),
    })
  }
  const handleChangeContractType = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setEditingField({[fieldName]: CONTRACT_TYPE_LIST.find((type) => type.value === fieldValue)})
  }
  const handleEditingMaterialType = (fieldName) => (e) => {
    setEditingField({
      [fieldName]: MATERIAL_TYPE_LIST.find((type) => type.value === contractDetail?.material_type),
    })
  }
  const handleChangeMaterialType = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setEditingField({[fieldName]: MATERIAL_TYPE_LIST.find((type) => type.value === fieldValue)})
  }
  const handleEditingStatusType = (fieldName) => (e) => {
    setEditingField({
      [fieldName]: CONTRACT_STATUS_LIST.find((type) => type.value === contractDetail?.status),
    })
  }
  const handleChangeStatusType = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setEditingField({[fieldName]: CONTRACT_STATUS_LIST.find((type) => type.value === fieldValue)})
  }

  const handleEditingContractMachine = (fieldName) => (e) => {
    setEditingField({[fieldName]: contractDetail?.machine?.id || ''})
  }

  const handleEditingContract = (fieldName) => (e) => {
    setEditingField({[fieldName]: contractDetail[fieldName] || ''})
  }

  const handleEditingNetto = (fieldName) => (e) => {
    setEditingField({
      netto:
        contractDetail?.netto *
          (contractDetail?.material_type === 'WOOD' ? 1 : CUBIC_METERS_TO_PRM) || '',
    })
  }

  const handleEditTitle = (fieldName) => {
    setEditingField({[fieldName]: locationDetail[fieldName] || ''})
  }

  const closeBigImageCarousel = () => {
    setOpenBigCarousel(null)
  }
  const openBigImageCarousel = (index) => () => {
    setOpenBigCarousel(index)
  }

  const handleOnChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setEditingField({[fieldName]: fieldValue})
  }

  const handleOnChangeDates = (values) => {
    setEditingField((prev) => ({...prev, ...values}))
  }

  const handleEditingDateField = (values) => (e) => {
    setEditingField(values)
  }

  const handleUpdateLocation = (e) => {
    updateLocation(locationDetail.id, prepareLocationData(editingField))
      .then((res) => {
        fireSuccessToast(<Trans>Location edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Location edit failed.</Trans>)
      })
      .finally(() => {
        getLocation(locationDetail.id)
        setEditingField(null)
        getLocations()
      })
  }

  const handleUpdateContract = (e) => {
    updateContract(locationDetail.id, contractDetail.id, prepareContractData(editingField))
      .then((res) => {
        fireSuccessToast(<Trans>Contract edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Contract edit failed.</Trans>)
      })
      .finally(() => {
        getContract(locationDetail.id, contractDetail.id)
        setEditingField(null)
        getLocations()
      })
  }

  const handleEditCoords = (field) => (e) => {
    setOpenEditCoords(true)
  }
  const handleCloseEditCoords = () => {
    setOpenEditCoords(false)
  }

  useEffect(() => {
    if (locationDetail.id) {
      if (hasContract) {
        getContract(locationDetail.id, locationDetail.contracts[0].id)
      }
    }
    getMachines()
  }, [locationDetail])

  const getLocationAndContractImages = () => {
    const locationImages = locationDetail?.images ? locationDetail?.images : []
    const contractImages = contractDetail?.images ? contractDetail?.images : []

    return [
      ...locationImages.map((locImg) => {
        return {...locImg, title: <Trans>Location</Trans>, resource: 'location'}
      }),
      ...contractImages?.map((conImg) => {
        return {...conImg, title: <Trans>Contract</Trans>, resource: 'contract'}
      }),
    ]
  }

  const isProcessedAmountAvailable = () =>
    pastShifts &&
    locationDetail?.id &&
    contractDetail?.id &&
    !['PLANNED', 'FINISHED'].includes(contractDetail?.status)
  const getProcessedAmount = () =>
    isProcessedAmountAvailable() &&
    Math.round(
      (pastShifts.reduce((sumShifts, shift) => {
        return (
          sumShifts +
          shift.transports.reduce((sumTransports, transport) => {
            return sumTransports + transport.netto
          }, 0)
        )
      }, 0) *
        100) /
        contractDetail?.netto
    )

  useEffect(() => {
    if (locationDetail.id && contractDetail?.id) {
      getShifts(locationDetail.id, contractDetail?.id)
    }
  }, [locationDetail, contractDetail])

  return (
    <>
      <DialogWindow
        className={classes.transparentDialog}
        fullScreen
        open={openBigCarousel !== null}
        onClose={closeBigImageCarousel}
      >
        <Carousel
          autoPlay={false}
          index={openBigCarousel}
          animation={'slide'}
          fullHeightHover={false}
          className={classes.bigCarousel}
        >
          {getLocationAndContractImages().map((item, i) => (
            <Paper
              elevation={0}
              key={i}
              className={classes.bigCarouselPaper}
              onClick={openBigImageCarousel(i)}
            >
              <img src={item.url} alt={item.id} className={classes.bigCarouselImage} />
            </Paper>
          ))}
        </Carousel>
      </DialogWindow>

      {/* EDIT COORDS DIALOG */}
      <DialogWindow
        title={<Trans>Edit coords</Trans>}
        maxWidth={'sm'}
        open={openEditCoords}
        onClose={handleCloseEditCoords}
        className={gettingLocationFromMap ? classes.hideModal : ''}
      >
        <EditCoordsForm
          onClose={handleCloseEditCoords}
          locationFromMapCoords={locationFromMapCoords}
          handleLocationFromMap={handleLocationFromMap}
          coordinates={locationDetail.coordinates}
        />
      </DialogWindow>

      <GridItem
        container
        xs={12}
        md={6}
        alignItems={'flex-start'}
        className={classes.locationColumnHeight}
      >
        {/* FIRST ROW WITH TITLE NAME OF LOCATION */}
        <LocationDetailTitleRow
          locationDetail={locationDetail}
          editingField={editingField}
          onChange={handleOnChange}
          onSave={handleUpdateLocation}
          onEdit={handleEditTitle}
          borderBottom={true}
          onClose={onClose}
          hasContract={hasContract}
        />

        <EditFieldRow>
          <div
            className={cx(classes.locationEditRowTitle, classes.uppercase, classes.scrollShadow)}
          >
            <Trans>Location info</Trans>
          </div>
        </EditFieldRow>

        <GridItem container xs={12} className={classes.locationDetailScroll}>
          {/* COORDINATES */}
          <EditCoordinateField
            defaultValue={locationDetail?.coordinates}
            name={'coordinates'}
            label={<Trans>Coordinates</Trans>}
            onEdit={handleEditCoords}
            enabled={isManager}
          />

          {/* WORK DATE START AND END FIELD */}
          {hasContract && (
            <EditContractDateField
              defaultStart={contractDetail?.start}
              start={editingField?.start}
              defaultEnd={contractDetail?.end}
              end={editingField?.end}
              editing={editingField?.hasOwnProperty('start')}
              enabled={isManager}
              label={<Trans>Work (from - to)</Trans>}
              onChange={handleOnChangeDates}
              onEdit={handleEditingDateField}
              onSave={handleUpdateContract}
              borderSide={'right'}
            />
          )}

          {/* ADDRESS FIELD */}
          {locationDetail.type !== 'WORK' && (
            <EditTextField
              defaultValue={locationDetail?.address}
              value={editingField?.address}
              editing={editingField?.hasOwnProperty('address')}
              enabled={isManager}
              label={<Trans>Address</Trans>}
              name={'address'}
              onChange={handleOnChange}
              onSave={handleUpdateLocation}
              onEdit={handleEditingField}
            />
          )}

          {/* MACHINE SELECT FIELD */}
          <EditSelectField
            defaultValue={contractDetail?.machine?.name}
            value={editingField?.machine_id}
            editing={editingField?.hasOwnProperty('machine_id')}
            enabled={isManager}
            label={<Trans>Machine</Trans>}
            name={'machine_id'}
            onChange={handleOnChange}
            onSave={handleUpdateContract}
            onEdit={handleEditingContractMachine}
            options={machineList}
            loading={machineLoading}
            borderSide={'right'}
            edgeSide={'left'}
            emptyOption={true}
          />

          {/* TYPE SELECT FOR CONTRACT */}
          {hasContract && (
            <EditSelectField
              defaultValue={
                contractDetail?.type &&
                CONTRACT_TYPE_LIST.find((type) => type.value === contractDetail?.type).label
              }
              value={editingField?.type?.value}
              editing={editingField?.hasOwnProperty('type')}
              enabled={isManager}
              label={<Trans>Type</Trans>}
              name={'type'}
              onChange={handleChangeContractType}
              onSave={handleUpdateContract}
              onEdit={handleEditingContractType}
              options={CONTRACT_TYPE_LIST}
              borderSide={'right'}
              // edgeSide={'left'}
            />
          )}

          {/* MATERIAL TYPE FIELD */}
          {hasContract && (
            <EditSelectField
              defaultValue={
                contractDetail?.material_type &&
                MATERIAL_TYPE_LIST.find((type) => type.value === contractDetail?.material_type)
                  .label
              }
              value={editingField?.material_type?.value}
              editing={editingField?.hasOwnProperty('material_type')}
              enabled={isManager}
              label={<Trans>Material type</Trans>}
              name={'material_type'}
              onChange={handleChangeMaterialType}
              onSave={handleUpdateContract}
              onEdit={handleEditingMaterialType}
              options={MATERIAL_TYPE_LIST}
              borderSide={'right'}
              edgeSide={'left'}
            />
          )}

          {/* TO PROCESS FIELD */}
          {locationDetail.type === 'WORK' && (
            <EditTextField
              defaultValue={
                contractDetail?.netto *
                (contractDetail?.material_type === 'WOOD' ? 1 : CUBIC_METERS_TO_PRM)
              }
              value={editingField?.netto}
              editing={editingField?.hasOwnProperty('netto')}
              enabled={isManager}
              label={<Trans>To process</Trans>}
              name={'netto'}
              onChange={handleOnChange}
              onSave={handleUpdateContract}
              onEdit={handleEditingNetto}
              borderSide={'right'}
              units={contractDetail?.material_type === 'WOOD' ? 'm³' : 'prm'}
            />
          )}

          {/* STATUS SELECT FOR CONTRACT */}
          {hasContract && (
            <EditSelectField
              defaultValue={
                contractDetail?.status &&
                CONTRACT_STATUS_LIST.find((type) => type.value === contractDetail?.status).label
              }
              value={editingField?.status?.value}
              editing={editingField?.hasOwnProperty('status')}
              enabled={isManager}
              label={
                <>
                  <Trans>Status</Trans>
                  {isProcessedAmountAvailable() && (
                    <>
                      &nbsp;-&nbsp;<Trans>processed cca</Trans>
                      &nbsp;{getProcessedAmount()}%
                    </>
                  )}
                </>
              }
              name={'status'}
              onChange={handleChangeStatusType}
              onSave={handleUpdateContract}
              onEdit={handleEditingStatusType}
              options={CONTRACT_STATUS_LIST}
              borderSide={'right'}
              edgeSide={'left'}
            />
          )}

          {/* DESCRIPTION FIELD FOR LOCATION */}
          <EditTextAreaField
            defaultValue={locationDetail?.description}
            value={editingField?.locationDescription}
            name={'locationDescription'}
            editing={editingField?.hasOwnProperty('locationDescription')}
            enabled={true}
            onChange={handleOnChange}
            onSave={handleUpdateLocation}
            onEdit={handleEditingField}
            label={<Trans>Location description</Trans>}
            textFieldProps={{rows: 3, multiline: true}}
          />

          {/* DESCRIPTION FIELD FOR CONTRACT */}
          {hasContract && (
            <EditTextAreaField
              defaultValue={contractDetail?.description}
              value={editingField?.contractDescription}
              name={'contractDescription'}
              editing={editingField?.hasOwnProperty('contractDescription')}
              enabled={true}
              onChange={handleOnChange}
              onSave={handleUpdateContract}
              onEdit={handleEditingField}
              label={<Trans>Contract description</Trans>}
              textFieldProps={{rows: 3, multiline: true}}
            />
          )}

          {/* SMALL CAROUSEL IN BOTTOM OF PAGE */}
          <ImageCarousel
            images={getLocationAndContractImages()}
            openBigImageCarousel={openBigImageCarousel}
            locationId={locationDetail.id}
            contractId={hasContract && contractDetail?.id}
            hasContract={hasContract}
          />
        </GridItem>
      </GridItem>
    </>
  )
}

LocationDetail.propTypes = {
  classes: PropTypes.object,
  locationDetail: PropTypes.object,
  onClose: PropTypes.func,
  getMachines: PropTypes.func,
  machineList: PropTypes.array,
  machineLoading: PropTypes.bool,
  hasContract: PropTypes.bool,
  getContract: PropTypes.func,
  getLocations: PropTypes.func,
  getLocation: PropTypes.func,
  locationFromMapCoords: PropTypes.string,
  handleLocationFromMap: PropTypes.func,
  gettingLocationFromMap: PropTypes.bool,
  isManager: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocation,
      getContract,
      getLocations,
      getLocation,
      updateContract,
      getMachines,
      getShifts,
    },
    dispatch
  )
}

export default compose(
  withStyles(locationDetailStyle),
  connect((store) => {
    return {
      locationsLoading: store.locations.loading,
      locationDetail: store.locations.detail,
      contractDetail: store.contracts.detail,
      machineList: store.machines.list,
      machineLoading: store.machines.loading,
      pastShifts: store.shifts.pastShifts,
      isManager: store.user.myAccount.isManager,
    }
  }, mapDispatchToProps)
)(LocationDetail)
