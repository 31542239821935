import React, {useEffect, useState} from 'react'
import LocationDetail from 'component/MapLayoutPage/locationDetail/LocationDetail'
import ShiftDetail from 'component/MapLayoutPage/shiftDetail/ShiftDetail'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getContract, getContracts} from 'redux/action/contractAction'

const LocationDetailDialog = (props) => {
  const {
    locationDetail,
    locationsLoading,
    onClose,
    getContracts,
    getContract,
    locationFromMapCoords,
    handleLocationFromMap,
    gettingLocationFromMap,
  } = props

  const [hasContract, setHasContract] = useState(false)

  useEffect(() => {
    if (locationDetail.id) {
      getContracts(locationDetail.id)
    }
  }, [locationDetail.id])

  useEffect(() => {
    const contracts = locationDetail.contracts
    if (contracts.length > 0) {
      let activeContract = contracts.find((contract) => contract.is_active === true)
      activeContract = activeContract ? activeContract : contracts[0]
      getContract(locationDetail.id, activeContract.id)
      setHasContract(true)
    } else {
      setHasContract(false)
    }
  }, [locationDetail.contracts])

  return (
    <>
      {!locationsLoading && (
        <>
          {/* LEFT COLUMN */}
          <LocationDetail
            detail={locationDetail}
            hasContract={hasContract}
            onClose={onClose}
            locationFromMapCoords={locationFromMapCoords}
            handleLocationFromMap={handleLocationFromMap}
            gettingLocationFromMap={gettingLocationFromMap}
          />
          {/* RIGHT COLUMN */}
          {hasContract && <ShiftDetail onClose={onClose} locationDetail={locationDetail} />}
        </>
      )}
    </>
  )
}

LocationDetailDialog.propTypes = {
  locationDetail: PropTypes.object,
  locationsLoading: PropTypes.bool,
  onClose: PropTypes.func,
  getContracts: PropTypes.func,
  getContract: PropTypes.func,
  locationFromMapCoords: PropTypes.string,
  handleLocationFromMap: PropTypes.func,
  gettingLocationFromMap: PropTypes.bool,
  contractList: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContracts,
      getContract,
    },
    dispatch
  )
}

export default compose(
  withStyles(mapLayoutPageStyle),
  connect((store) => {
    return {
      locationsLoading: store.locations.loading,
      locationDetail: store.locations.detail,
    }
  }, mapDispatchToProps)
)(LocationDetailDialog)
