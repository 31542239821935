import React, {useEffect} from 'react'
import {Marker} from 'react-leaflet'
import L from 'leaflet'
import LocationPin from 'style/asset/pin-my-location.svg'

const CurrentMarker = ({currentLocation, map, specificZoom}) => {
  useEffect(() => {
    if (currentLocation && map) map.setView([currentLocation.y, currentLocation.x], specificZoom)
  }, [currentLocation, map])

  return (
    <Marker
      position={[currentLocation.y, currentLocation.x]}
      icon={L.icon({
        iconUrl: LocationPin,
        iconSize: [33, 49],
        iconAnchor: [16, 24],
      })}
      title={'Vaše poloha'}
    />
  )
}

export default CurrentMarker
