import {MEDIA_MOBILE} from 'helper/constants'

const locationDetailStyle = (theme) => ({
  shiftTabs: {
    '& .MuiTab-root': {
      textTransform: 'inherit',
      fontSize: '16px',
    },
  },
  pastShifts: {
    height: 'calc(100% - 120px)',
    maxHeight: 'calc(100% - 120px)',
    paddingBottom: 20,
    overflowY: 'auto',
    placeContent: 'flex-start',
  },
  emptyPastShifts: {
    borderBottom: '2px dashed ' + theme.palette.secondary.light,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    margin: '0 20px 0 10px',
  },

  // carousel paper containing image
  carouselPaper: {
    boxSizing: 'border-box',
    maxHeight: 200,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  newImageContainer: {
    height: 300,
    padding: '12px 68px',
    boxSizing: 'border-box',

    '& img': {
      maxWidth: '100%',
      maxHeight: '230px',
      width: 'auto',
      height: 'auto',
    },
  },
  carouselEmptyContainer: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius,
    height: 200,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  deleteImageIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  imageResource: {
    cursor: 'pointer',
    textTransform: 'lowercase',
    position: 'absolute',
    top: 16,
    left: 16,
    textAlign: 'center',
    padding: '4px 8px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
  },

  transparentDialog: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      // all icons
      '& .MuiIconButton-root': {
        '& .MuiSvgIcon-root': {
          width: '28px',
          height: '28px',
        },
      },
      // only the icon right after .MuiPaper class which is close icon button
      '& > .MuiIconButton-root': {
        '& .MuiSvgIcon-root': {
          width: '40px !important',
          height: '40px !important',
          fill: 'white',
        },
      },
    },
  },
  bigCarousel: {
    height: '100%',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bigCarouselPaper: {
    height: '90vh',
    boxSizing: 'border-box',
    padding: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bigCarouselImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  rightEdge: {
    height: 60,
    paddingRight: '20px !important',
  },
  leftEdge: {
    height: 60,
    paddingLeft: '20px !important',
  },
  borderRight: {
    borderRight: '1px solid ' + theme.palette.secondary.light,
    paddingRight: '10px !important',
  },
  borderLeft: {
    borderLeft: '1px solid ' + theme.palette.secondary.light,
    paddingLeft: '10px !important',
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.secondary.light,
    padding: '5px 10px',
    [MEDIA_MOBILE]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    height: 60,
  },

  leftColumnField: {
    paddingLeft: '10px',
    paddingRight: '10px',
    [MEDIA_MOBILE]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },

  saveCloseButtonIcon: {
    margin: 2,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    boxSizing: 'border-box',
    padding: 0,
    marginLeft: 10,
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main,
    },
    '& svg': {
      padding: 2,
      width: 16,
      height: 16,
      fill: theme.palette.primary.contrastText,
    },
  },

  editIconButtonPadding: {
    padding: 0,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: 'transparent',

      '& svg': {
        opacity: 1,
        transition: 'opacity 200ms',
      },
    },
    '& svg': {
      opacity: 0.5,
      fill: theme.palette.primary.dark,
      transition: 'opacity 200ms',
    },
  },

  locationTitle: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: 26,
  },

  imageCarouselContainer: {
    padding: '16px 20px',
    position: 'relative',
    borderRight: '1px solid ' + theme.palette.secondary.light,
  },

  addImageIconPadding: {
    paddingRight: 10,
    alignSelf: 'center',
    display: 'flex',
  },

  addImageIconButton: {
    top: 12,
    left: 12,
    position: 'absolute',
  },
  editImageIconButton: {
    top: 12,
    right: 12,
    position: 'absolute',
  },

  selectContractField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: 60,
    boxSizing: 'border-box',
  },

  locationDetailCloseButton: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  closeButtonBorders: {
    borderBottom: '1px solid ' + theme.palette.secondary.light,
    borderLeft: '1px solid ' + theme.palette.secondary.light,
  },
  labelPaddingRight: {
    paddingRight: 10,
  },
  locationCloseButton: {
    width: 29.2,
    height: 29.2,
  },

  contractSelectRoot: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: 40,
    padding: '10px 14px',
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.primary.main,
    boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
  },
  contractSelectItem: {
    fontSize: 14,
  },

  flexGrowSelect: {
    flexGrow: 1,
  },

  // class for description text areas
  textArea: {
    minHeight: 60,
    height: 'auto',
    '& .MuiOutlinedInput-root': {
      padding: '10px 6px',
      fontSize: 14,
    },
    '& .MuiIconButton-root': {
      marginLeft: 10,
      padding: 0,
    },
  },
  textAreaDefaultValue: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },

  // end adornment within depot netto field
  depotMetersEndAdornment: {
    '& .MuiIconButton-root': {
      padding: 6,
      fontSize: 14,
    },
  },

  // icon inside the menu
  menuItemIcon: {
    display: 'flex',
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: 20,
      opacity: 0.5,
      fill: theme.palette.primary.dark,
    },
  },

  // styling add icon inside button
  addCircleIconPadding: {
    paddingRight: 10,
    alignSelf: 'center',
    display: 'flex',
  },
  // styling of text
  locationEditRowTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    boxSizing: 'border-box',
    width: 'auto',
  },
  locationEditRowTitleNoPadding: {
    paddingTop: 0,
  },
  editFieldRow: {
    padding: '0px 10px',
  },

  uppercase: {
    textTransform: 'uppercase',
  },

  // transports table
  tableRowContainer: {
    borderTop: '2px dashed ' + theme.palette.secondary.main,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,

    '&:last-child': {
      borderBottom: '2px dashed ' + theme.palette.secondary.main,
    },
  },
  transportTableButtons: {
    '& div:first-child': {
      marginRight: 10,
    },
  },
  transportArrowIcon: {
    padding: '0 5px',
    display: 'flex',
  },

  scrollHeightTransports: {
    height: 'calc(100% - 240px)',
    maxHeight: 'calc(100% - 240px)',
    paddingBottom: 20,
    overflowY: 'auto',
  },
  scrollShadow: {
    width: '100%',
    boxShadow: '0px 4px 6px -8px rgb(0 0 0 / 65%)',
  },

  emptyOption: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },

  // set height of shift column to for scrolling
  locationColumnHeight: {
    height: 'calc(100vh - 64px)',
    maxHeight: 'calc(100vh - 64px)',
    alignItems: 'flex-start',
    placeContent: 'flex-start',
  },
  locationDetailScroll: {
    height: 'calc(100% - 120px)',
    maxHeight: 'calc(100% - 120px)',
    overflowY: 'auto',
    alignContent: 'flex-start',
  },

  hideModal: {
    display: 'none',
  },
})

export default locationDetailStyle
