const SeznamLogo = () => {
  return (
    <a
      href={'http://mapy.cz/'}
      target={'_blank'}
      style={{position: 'absolute', bottom: '20px', right: '20px', zIndex: 1000}}
    >
      <img src="https://api.mapy.cz/img/api/logo.svg" alt={'seznam'} />
    </a>
  )
}

export default SeznamLogo
