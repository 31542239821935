import React, {useEffect, useState} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import 'leaflet/dist/leaflet.css'
import {MapContainer, ScaleControl} from 'react-leaflet'
import TileLayers from 'component/MapLayoutPage/map/TileLayers'
import SeznamLogo from 'component/MapLayoutPage/map/SeznamLogo'
import TargetMarker from 'component/MapLayoutPage/map/TargetMarker'
import LocationMarker from 'component/MapLayoutPage/map/LocationMarker'
import CurrentMarker from 'component/MapLayoutPage/map/CurrentMarker'

const Map = (props) => {
  const {
    classes,
    mapCenter,
    locations,
    currentLocation,
    gettingLocationFromMap,
    getLocationFromMap,
    openLocationDetailFromMap,
  } = props
  const [map, setMap] = useState(null)

  const defaultZoom = 8
  const specificZoom = 16

  useEffect(() => {
    if (map && mapCenter) {
      map.setView([mapCenter.y, mapCenter.x], specificZoom)
    }
  }, [mapCenter])

  return (
    <MapContainer
      className={classes.mapContainer}
      center={[mapCenter.y, mapCenter.x]}
      zoom={defaultZoom}
      whenCreated={setMap}
    >
      <TileLayers />

      <ScaleControl imperial={false} maxWidth={200} position="topright" />

      <SeznamLogo />

      {gettingLocationFromMap ? (
        <TargetMarker getLocationFromMap={getLocationFromMap} map={map} mapCenter={mapCenter} />
      ) : (
        <>
          {currentLocation && (
            <CurrentMarker
              currentLocation={currentLocation}
              map={map}
              specificZoom={specificZoom}
            />
          )}
          {locations?.map((loc) => (
            <LocationMarker
              key={loc.id}
              location={loc}
              specificZoom={specificZoom}
              openLocationDetailFromMap={openLocationDetailFromMap}
              map={map}
            />
          ))}
        </>
      )}
    </MapContainer>
  )
}

export default withStyles(mapLayoutPageStyle)(Map)
