import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import DatePickerInput from 'component/field/DatePickerInput'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import SelectInput from 'component/field/SelectInput'
import {CONTRACT_STATUS_LIST, CONTRACT_TYPE_LIST, MATERIAL_TYPE_LIST} from 'helper/constants'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getMachines} from 'redux/action/machineAction'
import InputAdornment from '@material-ui/core/InputAdornment'
import useWindowSize from 'helper/useWindowSize'

const CreateContractForm = (props) => {
  const {handleSubmit, classes, getMachines, machineList, machineLoading} = props

  const {isMediaMobile} = useWindowSize()

  useEffect(() => {
    getMachines()
  }, [])

  return (
    <Box p={5}>
      <Form onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem
                  container
                  xs={12}
                  sm={6}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <div className={classes.fieldLabel}>
                    <Trans>Start</Trans>
                  </div>
                  <Field name="start" component={DatePickerInput} validate={required} />
                </GridItem>
                <GridItem
                  container
                  xs={12}
                  sm={6}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <div className={classes.fieldLabel}>
                    <Trans>End</Trans>
                  </div>
                  <Field name="end" component={DatePickerInput} validate={required} />
                </GridItem>

                <GridItem
                  container
                  xs={12}
                  sm={6}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <div className={classes.fieldLabel}>
                    <Trans>Status</Trans>
                  </div>
                  <Field
                    name="status"
                    component={SelectInput}
                    options={CONTRACT_STATUS_LIST}
                    validate={required}
                    defaultValue={'PLANNED'}
                  />
                </GridItem>
                <GridItem
                  container
                  xs={12}
                  sm={6}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <div className={classes.fieldLabel}>
                    <Trans>Type</Trans>
                  </div>
                  <Field
                    name="type"
                    component={SelectInput}
                    options={CONTRACT_TYPE_LIST}
                    validate={required}
                    defaultValue={'OWN_RESOURCE'}
                  />
                </GridItem>

                <GridItem
                  container
                  xs={12}
                  sm={6}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <div className={classes.fieldLabel}>
                    <Trans>Material type</Trans>
                  </div>
                  <Field
                    name="material_type"
                    component={SelectInput}
                    options={MATERIAL_TYPE_LIST}
                    validate={required}
                    defaultValue={'WOOD'}
                  />
                </GridItem>
                <GridItem
                  container
                  xs={12}
                  sm={6}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <Field
                    name="netto"
                    label={
                      <>
                        <Trans>Netto</Trans>
                        {formProps.values.material_type === 'WOOD' ? ' (m³)' : ' (prm)'}
                      </>
                    }
                    component={TextInput}
                    placeholder={'120'}
                    type={'number'}
                    className={classes.fieldEndAdornmentPadding}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={classes.endAdornmentPadding} position="end">
                          {formProps.values.material_type === 'WOOD' ? '(m³)' : '(prm)'}
                        </InputAdornment>
                      ),
                    }}
                    inputJustifyRight={true}
                  />
                </GridItem>
                <GridItem
                  container
                  xs={12}
                  sm={12}
                  direction={'column'}
                  className={classes.smallGridPadding}
                >
                  <div className={classes.fieldLabel}>
                    <Trans>Machine</Trans>
                  </div>
                  <Field
                    name="machine_id"
                    component={SelectInput}
                    options={machineList}
                    loading={machineLoading}
                    defaultValue={machineList[0]?.id}
                    emptyOption={true}
                  />
                </GridItem>

                <GridItem xs={12} className={isMediaMobile && classes.smallGridPadding}>
                  <Field
                    name="description"
                    label={<Trans>Contract description</Trans>}
                    component={TextInput}
                    rows={3}
                    multiline={true}
                    placeholder={'Popis zakázky'}
                  />
                </GridItem>

                <GridItem container justify={'flex-end'}>
                  <PrimaryButton
                    fullWidth={false}
                    formSubmit={true}
                    textPadding={1}
                    size={'small'}
                    type="submit"
                    text={<Trans>Create</Trans>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </Box>
  )
}

CreateContractForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  machineList: PropTypes.array,
  getMachines: PropTypes.func,
  machineLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMachines,
    },
    dispatch
  )
}

export default compose(
  withStyles(fieldStyle),
  connect((store) => {
    return {
      machineList: store.machines.list,
      machineLoading: store.machines.loading,
    }
  }, mapDispatchToProps)
)(CreateContractForm)
