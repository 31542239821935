import {LayersControl, TileLayer} from 'react-leaflet'
import {MAP_API_KEY} from 'helper/constants'
import React from 'react'

const TileLayers = () => {
  return (
    <LayersControl position="bottomleft">
      <LayersControl.BaseLayer checked={true} name="Obecná">
        <TileLayer
          attribution='<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>'
          url={`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${MAP_API_KEY}`}
          minZoom={0}
          maxZoom={19}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Letecká">
        <TileLayer
          attribution='<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>'
          url={`https://api.mapy.cz/v1/maptiles/aerial/256/{z}/{x}/{y}?apikey=${MAP_API_KEY}`}
          minZoom={0}
          maxZoom={19}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Turistická">
        <TileLayer
          attribution='<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>'
          url={`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${MAP_API_KEY}`}
          minZoom={0}
          maxZoom={19}
        />
      </LayersControl.BaseLayer>
    </LayersControl>
  )
}

export default TileLayers
