import WorkPin from 'style/asset/pin-work.svg'
import DestinationPin from 'style/asset/pin-destination.svg'
import GaskPin from 'style/asset/pin-gas.svg'
import ServicePin from 'style/asset/pin-servis.svg'
import L from 'leaflet'
import {Marker} from 'react-leaflet'
import React from 'react'

const LocationMarker = ({location, specificZoom, openLocationDetailFromMap, map}) => {
  let LocPin = WorkPin
  if (location.type === 'DESTINATION') LocPin = DestinationPin
  if (location.type === 'GAS_STATION') LocPin = GaskPin
  if (location.type === 'SERVICE_STATION') LocPin = ServicePin

  return (
    <Marker
      position={[location.y, location.x]}
      icon={L.icon({
        iconUrl: LocPin,
        iconSize: [33, 49],
        iconAnchor: [16, 49],
      })}
      title={location.name}
      id={location.id}
      eventHandlers={{
        click: () => {
          openLocationDetailFromMap(location.id)
          map.setView([location.y, location.x], specificZoom)
        },
      }}
    />
  )
}
export default LocationMarker
