import React from 'react'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  CLEAR_LOCATION_DETAIL,
  LOCATION_CREATE_REQUEST,
  LOCATION_CREATE_SUCCESS,
  LOCATION_DELETE_FAIL,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DETAIL_FAIL,
  LOCATION_DETAIL_REQUEST,
  LOCATION_DETAIL_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_UPDATE_FAIL,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
  OFFLINE_LOCATION_DETAIL_SUCCESS,
  OFFLINE_LOCATION_LIST_SUCCESS,
} from 'redux/actionType'
import {fireWarningOffline, fireWarningToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {getOfflineContracts} from './contractAction'

export const getLocations = (filters = [], archived = false, activeWorkOnly = false) => {
  const prepareFilters = filters.join('&filters=')
  const locationFilters = !!prepareFilters ? '?filters=' + prepareFilters : ''

  return (dispatch) => {
    dispatch({type: LOCATION_LIST_REQUEST})
    return httpClient
      .get(endpoints.locations + locationFilters, {
        archived: archived,
        active_work_only: activeWorkOnly,
      })
      .then((res) => {
        dispatch({type: LOCATION_LIST_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: LOCATION_LIST_FAIL})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
        }
      })
  }
}

export const getOfflineLocations = () => {
  return (dispatch) => {
    const query = '?filters=DESTINATION&filters=WORK&archived=false&active_work_only=true'
    return httpClient
      .get(endpoints.locations + query)
      .then((res) => {
        dispatch({type: OFFLINE_LOCATION_LIST_SUCCESS, data: res.data})
        res.data.objects
          .filter((location) => location.type === 'WORK')
          .forEach((location) => {
            httpClient
              .get(`${endpoints.locations}/${location.id}`)
              .then((res) => {
                dispatch({
                  type: OFFLINE_LOCATION_DETAIL_SUCCESS,
                  data: res.data,
                  locationId: location.id,
                })
              })
              .then(() => {
                getOfflineContracts(location.id)(dispatch)
              })
              .catch(() => {})
          })
      })
      .catch(() => {})
  }
}

export const getLocation = (id) => {
  return (dispatch) => {
    dispatch({type: LOCATION_DETAIL_REQUEST})
    return httpClient
      .get(`${endpoints.locations}/${id}`)
      .then((res) => {
        dispatch({type: LOCATION_DETAIL_SUCCESS, data: res.data, locationId: id})
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: LOCATION_DETAIL_FAIL, locationId: id})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
          return Promise.reject()
        }
      })
  }
}

export const createLocation = (data) => {
  return (dispatch) => {
    dispatch({type: LOCATION_CREATE_REQUEST})
    return httpClient
      .post(endpoints.locations, data)
      .then((res) => {
        dispatch({type: LOCATION_CREATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => globalApiErrorHandler(err, dispatch))
  }
}

export const updateLocation = (id, data) => {
  return (dispatch) => {
    dispatch({type: LOCATION_UPDATE_REQUEST})
    return httpClient
      .put(`${endpoints.locations}/${id}`, data)
      .then((res) => {
        dispatch({type: LOCATION_UPDATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: LOCATION_UPDATE_FAIL})
        globalApiErrorHandler(err, dispatch)
      })
  }
}

export const deleteLocation = (id) => {
  return (dispatch) => {
    dispatch({type: LOCATION_DELETE_REQUEST})
    return httpClient
      .del(`${endpoints.locations}/${id}`)
      .then((res) => {
        dispatch({type: LOCATION_DELETE_SUCCESS})
        return res.data
      })
      .catch((err) => {
        dispatch({type: LOCATION_DELETE_FAIL})
        globalApiErrorHandler(err, dispatch)
      })
  }
}

export const updateLocationDepot = (id, data) => {
  return (dispatch) => {
    dispatch({type: LOCATION_UPDATE_REQUEST})
    return httpClient
      .post(`${endpoints.locations}/${id}/depot`, data)
      .then((res) => {
        dispatch({type: LOCATION_UPDATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: LOCATION_UPDATE_FAIL})
        globalApiErrorHandler(err, dispatch)
      })
  }
}

export const uploadLocationImage = (id, data) => {
  return httpClient
    .post(`${endpoints.locations}/${id}/images`, data)
    .then((res) => {
      return Promise.resolve()
    })
    .catch(globalApiErrorHandler)
}

export const deleteLocationImage = (locationId, imageId) => {
  return httpClient
    .del(`${endpoints.locations}/${locationId}/images/${imageId}`)
    .then((res) => {
      return Promise.resolve()
    })
    .catch(globalApiErrorHandler)
}

export const clearLocationDetail = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_LOCATION_DETAIL})
    return Promise.resolve()
  }
}
