import React, {useEffect, useState} from 'react'
import {Marker} from 'react-leaflet'
import L from 'leaflet'
import TargetPin from 'style/asset/pin-target.svg'

const TargetMarker = ({mapCenter, map, getLocationFromMap}) => {
  const [targetMarker, setTargetMarker] = useState(mapCenter)

  useEffect(() => {
    const {lat, lng} = map.getCenter()
    setTargetMarker({y: lat, x: lng})
  }, [])

  return (
    <Marker
      position={[targetMarker.y, targetMarker.x]}
      icon={L.icon({
        iconUrl: TargetPin,
        iconSize: [33, 49],
        iconAnchor: [16, 49],
      })}
      draggable={true}
      eventHandlers={{
        dragend: (e) => {
          const {lat, lng} = e.target.getLatLng()
          setTargetMarker({y: lat, x: lng})
          getLocationFromMap({y: lat, x: lng})
        },
      }}
    />
  )
}

export default TargetMarker
