import React, {useEffect, useState} from 'react'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {Trans} from '@lingui/macro'
import BoxFullWidth from 'component/material/BoxFullWidth'
import EditDateTimeField from 'component/MapLayoutPage/shiftDetail/EditDateTimeField'
import cx from 'classnames'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import EditFieldRow from 'component/editField/EditFieldRow'
import GridContainer from 'component/material/GridContainer'
import AddCircleIcon from '@material-ui/icons/AddCircleRounded'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
  createShift,
  createShiftInStorage,
  deleteTransport,
  deleteTransportInStorage,
  getShiftsFromStorage,
  updateShift,
  updateShiftInStorage,
} from 'redux/action/shiftAction'
import PropTypes, {oneOfType} from 'prop-types'
import {Typography} from '@material-ui/core'
import {
  fireErrorToast,
  fireSuccessToast,
  fireWarningToast,
  getDateTimeDBFormat,
  isEmptyObject,
} from 'helper/functions'
import {getUsers} from 'redux/action/userAction'
import EditSelectField from 'component/editField/EditSelectField'
import DialogWindow from 'component/material/DialogWindow'
import NewTransportDialog from 'component/MapLayoutPage/shiftDetail/NewTransportDialog'
import moment from 'moment'
import WarningDialog from 'component/material/WarningDialog'
import Loader from 'component/material/Loader'
import SingleShiftRow from 'component/MapLayoutPage/shiftDetail/SingleShiftRow'
import {Tooltip} from '@material-ui/core'
import EditTransportDialog from 'component/MapLayoutPage/shiftDetail/EditTransportDialog'

const CurrentShiftTab = (props) => {
  const {
    classes,
    locationDetail,
    contractDetail,
    getShiftsFromStorage,
    currentShift,
    createShift,
    updateShift,
    getUsers,
    userList,
    deleteTransport,
    shiftLoading,
    shiftCreateLoading,
    shiftUpdateLoading,
    shiftsLoading,
  } = props

  const [editingField, setEditingField] = useState(null)
  const [openCreateTransport, setOpenCreateTransport] = useState(false)
  const [openDeleteTransport, setOpenDeleteTransport] = useState({
    shiftId: null,
    transportId: null,
    visible: false,
  })
  const [openEditTransport, setOpenEditTransport] = useState({
    shiftId: null,
    transportId: null,
    visible: false,
    transport: {},
  })
  const [selectedUserId, setSelectedUserId] = useState(null)

  const handleSetSelectedUser = (e) => {
    const fieldValue = e.target.value
    setSelectedUserId(fieldValue)
  }

  const handleOnChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setEditingField({[fieldName]: fieldValue})
  }

  const handleEditingField = (field) => (e) => {
    setEditingField({[field]: currentShift[field] || ''})
  }

  const openCreateTransportDialog = () => {
    setOpenCreateTransport(true)
  }
  const closeCreateTransportDialog = () => {
    setOpenCreateTransport(false)
  }

  const prepareStartShiftData = () => {
    return {
      start: getDateTimeDBFormat(moment()),
      user_id: selectedUserId,
      machine_id: contractDetail.machine?.id,
    }
  }
  const handleStartShift = () => {
    const shiftData = prepareStartShiftData()
    createShift(locationDetail.id, contractDetail.id, shiftData)
      .then((res) => {
        fireSuccessToast(<Trans>Shift started.</Trans>)
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          createShiftInStorage(locationDetail.id, contractDetail.id, {
            ...shiftData,
            isOffline: true,
            offlineId: err.offlineId,
          })
          getShiftsFromStorage(locationDetail.id, contractDetail.id)
          fireWarningToast(<Trans>Offline mode, data will be synced later</Trans>)
        } else {
          fireErrorToast(<Trans>Shift start failed.</Trans>)
        }
      })
      .finally(() => {})
  }

  const prepareEndShiftData = () => {
    const data = {
      ...currentShift,
      end: getDateTimeDBFormat(moment()),
    }
    if (currentShift.isOffline) {
      data.isOffline = true
      data.offlineId = currentShift.offlineId
    }
    return data
  }
  const handleEndShift = () => {
    const shiftData = prepareEndShiftData()
    updateShift(locationDetail.id, contractDetail.id, currentShift.id, shiftData)
      .then((res) => {
        fireSuccessToast(<Trans>Shift ended</Trans>)
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          updateShiftInStorage(locationDetail.id, contractDetail.id, currentShift.id, shiftData)
          getShiftsFromStorage(locationDetail.id, contractDetail.id)
          fireWarningToast(<Trans>Offline mode, data will be synced later</Trans>)
        } else {
          fireErrorToast(<Trans>Ending shift failed.</Trans>)
        }
      })
      .finally(() => {})
  }

  const handleUpdateShift = () => {
    setEditingField(null)
  }

  const openDeleteTransportDialog = (shiftId, transportId, transportOfflineId) => (e) => {
    setOpenDeleteTransport({
      shiftId: shiftId,
      transportId: transportId,
      transportOfflineId,
      visible: true,
    })
  }
  const closeDeleteTransportDialog = () => {
    setOpenDeleteTransport({shiftId: null, transportId: null, visible: false})
  }

  const openEditTransportDialog = (shiftId, transportId, transportOfflineId) => (e) => {
    const transport = currentShift.transports.find(
      (item) =>
        (transportId && item.id === transportId) ||
        (transportOfflineId && item.transportOfflineId === transportOfflineId)
    )
    console.log({transportId, transportOfflineId, transport})
    setOpenEditTransport({
      shiftId: shiftId,
      transportId: transportId,
      transportOfflineId,
      visible: true,
      transport,
    })
  }
  const closeEditTransportDialog = () => {
    setOpenEditTransport({shiftId: null, transportId: null, visible: false})
  }

  const handleDeleteTransport = () => {
    deleteTransport(
      locationDetail.id,
      contractDetail.id,
      currentShift.id,
      openDeleteTransport.transportId,
      openDeleteTransport.transportOfflineId
    )
      .then((res) => {
        fireSuccessToast(<Trans>Transport deleted.</Trans>)
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          deleteTransportInStorage(
            locationDetail.id,
            contractDetail.id,
            currentShift.id,
            openDeleteTransport.transportId,
            openDeleteTransport.transportOfflineId
          )
          getShiftsFromStorage(locationDetail.id, contractDetail.id)
          fireWarningToast(<Trans>Offline mode, data will be synced later</Trans>)
        } else {
          fireErrorToast(<Trans>Adding transport failed.</Trans>)
        }
      })
      .finally(() => {
        closeDeleteTransportDialog()
      })
  }

  useEffect(() => {
    if (locationDetail?.id && contractDetail?.id) {
      getShiftsFromStorage(locationDetail.id, contractDetail.id)
    }
  }, [locationDetail, contractDetail])

  useEffect(() => {
    if (currentShift?.user_id) {
      setSelectedUserId(userList.find((user) => user.id === currentShift.user_id)?.id || null)
    }
  }, [currentShift])

  return (
    <>
      {/* CREATE AND EDIT NEW TRANSPORT DIALOG */}
      <DialogWindow open={openCreateTransport} onClose={closeCreateTransportDialog} maxWidth={'sm'}>
        <NewTransportDialog onClose={closeCreateTransportDialog} />
      </DialogWindow>

      {/* CONFIRM DELETE TRANSPORT */}
      <WarningDialog
        title={<Trans>Delete transport</Trans>}
        open={openDeleteTransport.visible}
        onClose={closeDeleteTransportDialog}
        onConfirm={handleDeleteTransport}
      />

      {/* EDIT TRANSPORT DIALOG */}
      <DialogWindow
        open={openEditTransport.visible}
        onClose={closeEditTransportDialog}
        maxWidth={'sm'}
      >
        <EditTransportDialog
          onClose={closeEditTransportDialog}
          transport={openEditTransport.transport}
        />
      </DialogWindow>

      {/* TODO: check when user is resolved */}
      <EditSelectField
        defaultValue={
          selectedUserId !== null
            ? userList.find((user) => user.id === selectedUserId)?.name
            : currentShift?.user?.name
        }
        value={selectedUserId}
        editing={editingField?.hasOwnProperty('user_id')}
        label={<Trans>Operator</Trans>}
        name={'user_id'}
        onChange={handleSetSelectedUser}
        onSave={handleUpdateShift}
        onEdit={handleEditingField}
        options={userList}
        loading={false}
        responsiveness={{xs: 12, sm: 4}}
      />

      {/* START OF SHIFT */}
      {/* TODO: finish */}
      {!isEmptyObject(currentShift) ? (
        <EditDateTimeField
          defaultValue={currentShift?.start}
          value={editingField?.start}
          editing={editingField?.hasOwnProperty('start')}
          label={<Trans>Shift start</Trans>}
          name={'start'}
          responsiveness={{xs: 12, sm: 4}}
          onChange={handleOnChange}
          onSave={() => {}}
          onEdit={handleEditingField}
        />
      ) : (
        <GridItem
          className={cx(classes.borderLeft, classes.borderBottom)}
          container
          justify={'center'}
          alignItems={'center'}
          xs={12}
          sm={4}
        >
          <Tooltip
            title={<Trans>Machine and operator must be selected.</Trans>}
            placement="top"
            enterDelay={300}
            disableFocusListener
            disableTouchListener
            disableHoverListener={!!contractDetail?.machine}
          >
            <div>
              <PrimaryButton
                text={<Trans>Start shift</Trans>}
                textPadding={0}
                onClick={handleStartShift}
                size={'small'}
                fullWidth={false}
                disabled={!contractDetail?.machine || !selectedUserId || shiftCreateLoading}
              />
            </div>
          </Tooltip>
        </GridItem>
      )}

      {/* END OF SHIFT */}
      <GridItem
        className={cx(classes.borderLeft, classes.borderBottom)}
        container
        justify={'center'}
        alignItems={'center'}
        xs={12}
        sm={4}
      >
        {!isEmptyObject(currentShift) ? (
          <PrimaryButton
            text={<Trans>End shift</Trans>}
            textPadding={0}
            onClick={handleEndShift}
            size={'small'}
            fullWidth={false}
            disabled={shiftUpdateLoading}
          />
        ) : (
          <Typography variant={'body1'} component={'span'} color={'secondary'}>
            <Trans>Currently no shift</Trans>
          </Typography>
        )}
      </GridItem>

      <EditFieldRow
        borderBottom={false}
        leftEdge={false}
        className={currentShift?.transports?.length > 7 ? classes.scrollShadow : ''}
      >
        <div className={cx(classes.locationEditRowTitle, classes.locationEditRowTitleNoPadding)}>
          <Trans>Transports</Trans>
        </div>
        {!isEmptyObject(currentShift) && (
          <PrimaryButton
            onClick={openCreateTransportDialog}
            fullWidth={false}
            size={'small'}
            textPadding={0}
            text={
              <GridContainer justify={'center'} alignItems={'center'}>
                <div className={classes.addCircleIconPadding}>
                  <AddCircleIcon />
                </div>
                <Trans>Create transport</Trans>
              </GridContainer>
            }
            disabled={shiftsLoading}
          />
        )}
      </EditFieldRow>

      <GridItem
        container
        xs={12}
        direction={'row'}
        alignItems={'stretch'}
        className={classes.scrollHeightTransports}
      >
        <BoxFullWidth pl={2.5} pr={5}>
          {!isEmptyObject(currentShift) && currentShift.transports?.length > 0 ? (
            currentShift?.transports?.map((transport, index) => (
              <SingleShiftRow
                key={index}
                shift={currentShift}
                transport={transport}
                openDeleteTransportDialog={openDeleteTransportDialog}
                openEditTransportDialog={openEditTransportDialog}
              />
            ))
          ) : shiftLoading ? (
            <Loader />
          ) : (
            <GridContainer
              key={'empty'}
              className={classes.tableRowContainer}
              alignItems={'center'}
              justify={'center'}
            >
              <Typography variant={'body1'} component={'span'} color={'secondary'}>
                <Trans>No transport has taken place yet</Trans>
              </Typography>
            </GridContainer>
          )}
        </BoxFullWidth>
      </GridItem>
    </>
  )
}

CurrentShiftTab.propTypes = {
  classes: PropTypes.object,
  locationDetail: PropTypes.object,
  contractDetail: PropTypes.object,
  currentShift: oneOfType([PropTypes.object, PropTypes.array]),
  getShiftsFromStorage: PropTypes.func,
  createShift: PropTypes.func,
  updateShift: PropTypes.func,
  deleteTransport: PropTypes.func,
  getUsers: PropTypes.func,
  userList: PropTypes.array,
  shiftLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getShiftsFromStorage,
      createShift,
      updateShift,
      getUsers,
      deleteTransport,
    },
    dispatch
  )
}

export default compose(
  withStyles(locationDetailStyle),
  connect((store) => {
    return {
      locationsLoading: store.locations.loading,
      locationDetail: store.locations.detail,
      contractDetail: store.contracts.detail,
      currentShift: store.shifts.currentShift,
      userList: store.user.list,
      shiftLoading: store.shifts.listLoading,
      shiftCreateLoading: store.shifts.shiftCreateLoading,
      shiftUpdateLoading: store.shifts.shiftUpdateLoading,
      shiftsLoading: store.shifts.loading,
    }
  }, mapDispatchToProps)
)(CurrentShiftTab)
