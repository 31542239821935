import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {createShift, deleteTransport, getShifts, updateShift} from 'redux/action/shiftAction'
import {getUsers} from 'redux/action/userAction'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import shiftDetailStyle from 'component/MapLayoutPage/shiftDetail/shiftDetailStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'

const StatisticTab = (props) => {
  const {performances, classes} = props

  return (
    <div className={classes.statisticsScrollContainer}>
      <GridContainer className={classes.statisticsHead} alignContent={'center'}>
        <GridItem xs={4} className={classes.statisticsCell}>
          <Trans>Operator name</Trans>
        </GridItem>
        <GridItem xs={4} className={cx(classes.statisticsCell, classes.cellTextRight)}>
          <Trans>Hourly performance</Trans>
        </GridItem>
        <GridItem xs={4} className={cx(classes.statisticsCell, classes.cellTextRight)}>
          <Trans>Shift performance</Trans>
        </GridItem>
      </GridContainer>
      {performances?.map((performance, index) => (
        <GridContainer key={index} className={classes.statisticsRow} alignContent={'center'}>
          <GridItem xs={4} className={classes.statisticsCell}>
            {performance.user?.name}
          </GridItem>
          <GridItem xs={4} className={cx(classes.statisticsCell, classes.cellTextRight)}>
            {performance.hourly_performance === 'Infinity' ||
            performance.hourly_performance === 'NaN' ? (
              <Trans>Cannot be calculated </Trans>
            ) : (
              Math.round(performance.hourly_performance * 10) / 10 + ' m³'
            )}
          </GridItem>
          <GridItem xs={4} className={cx(classes.statisticsCell, classes.cellTextRight)}>
            {performance.shiftly_performance === 'Infinity' ||
            performance.hourly_performance === 'NaN' ? (
              <Trans>Cannot be calculated </Trans>
            ) : (
              Math.round(performance.shiftly_performance * 10) / 10 + ' m³'
            )}
          </GridItem>
        </GridContainer>
      ))}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getShifts,
      createShift,
      updateShift,
      getUsers,
      deleteTransport,
    },
    dispatch
  )
}

export default compose(
  withStyles(shiftDetailStyle),
  connect((store) => {
    return {performances: store.contracts.detail?.performances}
  }, mapDispatchToProps)
)(StatisticTab)
