import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import {ReactComponent as Close} from 'style/asset/close.svg'
import DialogActions from '@material-ui/core/DialogActions'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import useWindowSize from 'helper/useWindowSize'

const DialogWindow = (props) => {
  const {
    classes,
    children,
    dialogActions,
    open,
    onClose,
    title,
    fullWidth = true,
    maxWidth = 'lg',
    ...rest
  } = props

  const {isMediaMobile} = useWindowSize()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...rest}
      fullScreen={isMediaMobile}
    >
      <GridContainer direction={'row'}>
        {title && (
          <GridItem
            container
            justify={'center'}
            alignItems={'center'}
            xs={12}
            className={classes.dialogTitle}
          >
            <Typography variant={'h2'} color={'primary'}>
              {title}
            </Typography>
          </GridItem>
        )}
        {onClose ? (
          <div className={classes.dialogCloseButton} onClick={onClose}>
            <Close />
          </div>
        ) : null}
        <DialogContent className={classes.dialogContent}>{children}</DialogContent>
        {dialogActions && (
          <DialogActions className={classes.dialogActions}>{dialogActions}</DialogActions>
        )}
      </GridContainer>
    </Dialog>
  )
}

DialogWindow.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
}

export default withStyles(materialStyle)(DialogWindow)
